! function(e) {

    var n, t, i, a, o = ["xs", "sm", "md", "lg", "xl"],
        s = {
            xl: "(min-width: 1200px)",
            lg: "(min-width: 992px)",
            md: "(min-width: 768px)",
            sm: "(min-width: 576px)",
            xs: "(min-width: 0px)"
        };
    e("document").ready(function() {
        r()
    });

    function r() {
        t = e("body"), i = u(), d();
        e(window).on("resize", function(e, n, t) {
            var i;
            return function() {
                var a = this,
                    o = arguments,
                    s = t && !i;
                clearTimeout(i), i = setTimeout(function() {
                    i = null, t || e.apply(a, o)
                }, n), s && e.apply(a, o)
            }
        }(function() {
            i = u(), e(window).trigger("fuse::windowResized", [i, c, f]), a !== i && d()
        }, 400)), n = {
            watchMatchMedia: r,
            triggerMatchMediaChanged: d,
            getCurrentStep: u,
            isOrAbove: f,
            isOrBelow: c
        }, window.fuseMatchMedia = n
    }

    function u() {
        var n, t = !1;
        return e.each(s, function(e, i) {
            !t && window.matchMedia(i).matches && (n = e, t = !0)
        }), n
    }

    function d() {
        t.addClass("media-step-" + i), t.removeClass("media-step-" + a), a = i, e(window).trigger("fuse::matchMediaChanged", [i, c, f])
    }

    function f(e) {
        var n = o.indexOf(e);
        return o.indexOf(i) >= n
    }

    function c(e) {
        var n = o.indexOf(e);
        return o.indexOf(i) <= n
    }
}(jQuery),
function(e, n, t, i) {
    var a = "fuseRipple",
        o = {
            duration: 300,
            fromCenter: !1
        },
        s = {
            input: function(e) {
                var n = e.parentNode;
                if ("span" !== n.tagName.toLowerCase() || !n.classList.contains("fuse-ripple-ready")) {
                    var i = t.createElement("span");
                    i.className = e.className + " fuse-ripple-input-wrapper", e.className = "fuse-ripple-input", n.replaceChild(i, e), i.appendChild(e)
                }
            },
            img: function(e) {
                var n = e.parentNode;
                if ("span" !== n.tagName.toLowerCase() || !n.classList.contains("fuse-ripple-ready")) {
                    var i = t.createElement("span");
                    n.replaceChild(i, e), i.appendChild(e)
                }
            }
        };

    function r(n, t) {
        this.element = n, this.options = e.extend({}, o, t), this._defaults = o, this._name = a, this.init()
    }
    r.prototype = {
        init: function() {
            t.body;
            var n, i = this;
            n = i.element;
            var a = i.element.tagName.toLowerCase(); - 1 !== ["input", "img"].indexOf(a) && (s[a](i.element), n = i.element.parentElement), e(n).hasClass("fuse-ripple-ready") || e(n).addClass("fuse-ripple-ready"), n.addEventListener("mousedown", function(e) {
                e.stopPropagation(), i.triggerRipple(n, i.options, e)
            })
        },
        triggerRipple: function(n, t, i) {
            var a = e(n);
            a.find(".fuse-ripple").length > 0 && a.find(".fuse-ripple").remove();
            var o = e('<div class="fuse-ripple">'),
                s = a.offset(),
                r = 0,
                u = 0;
            "touches" in i && i.touches.length ? (r = i.touches[0].pageY - s.top, u = i.touches[0].pageX - s.left) : (r = i.pageY - s.top, u = i.pageX - s.left);
            var d = a.outerWidth(),
                f = a.outerHeight(),
                c = Math.sqrt(Math.pow(d, 2) + Math.pow(f, 2)),
                l = .6 * Math.max(d, f),
                p = "translate(" + parseInt(u - l / 2) + "px ," + parseInt(r - l / 2) + "px)";
            t.fromCenter && (p = "translate(" + parseInt(.2 * d) + "px ," + parseInt(.2 * f) + "px)");
            var m = "scale(1) " + p,
                h = c / l,
                g = "scale(" + h + ")" + " " + ("translate(" + .5 * (d - l) / h + "px ," + .5 * (f - l) / h + "px)"),
                v = "opacity " + t.duration + "ms linear, transform  " + t.duration + "ms cubic-bezier(0.4, 0, 0.2, 1)";
            t.fromCenter;
            var w = {
                    width: l + "px",
                    height: l + "px",
                    transform: m,
                    opacity: 1
                },
                b = {
                    opacity: "0",
                    transform: g,
                    transition: v
                };
            e(o).css(w), a.append(o), setTimeout(function() {
                e(o).css(b)
            }), setTimeout(function() {
                a.find(o).remove()
            }, t.duration)
        }
    }, e.fn[a] = function(n) {
        return this.each(function() {
            e.data(this, "plugin_" + a) || e.data(this, "plugin_" + a, new r(this, n))
        })
    }
}(jQuery, window, document),
function(e) {
    e.fn.fuseMdInput = function() {
        ! function(a) {
            for (var o, s = a, r = 0; o = s[r]; r++) i(e(o));
            s.on("blur", n), s.on("focus", t)
        }(this)
    };

    function n(n) {
        var t = e(this);
        i(t),
            function(e) {
                if (!e.parent().hasClass("form-group")) return;
                e.parent(".form-group").removeClass("md-focus")
            }(t)
    }

    function t(n) {
        var t = e(this);
        a(t),
            function(e) {
                if (!e.parent().hasClass("form-group")) return;
                e.parent(".form-group").addClass("md-focus")
            }(t)
    }

    function i(e) {
        0 !== e.val().length ? a(e) : e.removeClass("md-has-value")
    }

    function a(e) {
        e.addClass("md-has-value")
    }
}(jQuery),
function(e) {
    var n, t, i = !0,
        a = !1;

    function o() {
        f("", "fuse-aside-expanded"), i = !1, c("fuse::asideExpanded", [])
    }

    function s() {
        f("fuse-aside-expanded", ""), i = !0, c("fuse::asideCollapsed", [])
    }

    function r() {
        i ? n.expand() : n.collapse()
    }

    function u() {
        a ? d() : function() {
            if (a) return;
            s(), a = !0, f("", "fuse-aside-folded"), e("body").addClass("fuse-aside-folded"), c("fuse::asideFolded", [])
        }()
    }

    function d() {
        a && (o(), a = !1, f("fuse-aside-folded", ""), c("fuse::asideUnfolded", []))
    }
    e("document").ready(function() {
        d = e("#aside"), t = d, n = {
            collapse: s,
            expand: o,
            toggle: r,
            toggleFold: u
        }, window.fuseAside = n, e("[data-fuse-aside-toggle-fold]").on("click", function() {
            u()
        }), t.on("mouseenter touchstart", function() {
            a && i && o()
        }), e(document).on("mousemove touchstart", function(e) {
            t.is(e.target) || 0 !== t.has(e.target).length || a && !i && s()
        });
        var d
    });

    function f(n, t) {
        e("body").removeClass(n).addClass(t)
    }
    e(window).on("fuse::matchMediaChanged", function(n, t, o, s) {
        window.fuseAside || (o("md") ? f("fuse-aside-folded", "") : e("body").hasClass("fuse-aside-folded") && (a = !0, i = !0)), o("md") && d()
    });

    function c(n, t) {
        setTimeout(function() {
            e(window).trigger(n, t)
        }, 300)
    }
}(jQuery),
function(e) {
    var n, t = !1;
    window.fuseBar = n = {
        instances: {},
        toggle: a,
        closeAll: d
    };

    function i(e) {
        return n.instances[e]
    }

    function a(n) {
        var a = i(n);
        if (a.isActive) {
            a.opened ? o(n) : (s = n, d().then(function() {
                var n = i(s);
                n.el.css({
                    transform: "translate3D(0,0,0)"
                }), n.el.removeClass("fuse-bar-closed"), n.el.addClass("fuse-bar-open"), n.opened = !0, (t = e('<div class="fuse-bar-backdrop fuse-bar-backdrop-' + s.replace(" ", "-") + '"></div>')).hide(), n.el.after(t), t.fadeIn(), t.on("click", function(e) {
                    e.preventDefault(), e.stopPropagation(), o(s)
                }), f("fuse::barOpened", [s, n])
            }));
            var s
        }
    }

    function o(n, a) {
        var o = jQuery.Deferred(),
            s = i(n);
        s.el.css({
            transform: ""
        }), a ? r() : setTimeout(r, 300);

        function r() {
            s.el.removeClass("fuse-bar-open"), s.el.addClass("fuse-bar-closed"), o.resolve("closed")
        }
        return s.opened = !1, t && t.fadeOut(function() {
            e(this).remove()
        }), f("fuse::barClosed", [n, s]), o.promise()
    }
    e("document").ready(function() {
        e("[data-fuse-bar]").each(function(t) {
            ! function(e) {
                var t = e.data("fuse-bar"),
                    i = e.data("fuse-bar-media-step"),
                    a = e.data("fuse-bar-position");
                n.instances[t] = {
                    id: t,
                    el: e,
                    mediaStep: i || !1,
                    opened: !1,
                    isActive: !1,
                    position: a || "left"
                }, u(n.instances[t])
            }(e(this))
        }), e("[data-fuse-bar-toggle]").on("click", function(n) {
            a(e(this).data("fuse-bar-toggle"))
        }), e("[data-fuse-bar-close]").on("click", function(n) {
            o(e(this).data("fuse-bar-close"))
        }), e(window).on("fuse::matchMediaChanged", r)
    });

    function s(e) {
        e.el.addClass("fuse-bar"), e.el.addClass("position-" + e.position), e.isActive = !0, f("fuse::barInit", [e.id, e])
    }

    function r(t, i, a, o) {
        e.each(n.instances, function(e, n) {
            u(n)
        }), d()
    }

    function u(e) {
        e.mediaStep ? window.fuseMatchMedia.isOrBelow(e.mediaStep) ? s(e) : ((n = e).el.removeClass("fuse-bar"), n.el.removeClass("position-" + n.position), n.isActive = !1, f("fuse::barDestroy", [n.id, n])) : (s(e), o(e.id, !0));
        var n
    }
    e(window).on("resize", function() {});

    function d() {
        var t = [];
        return e.each(n.instances, function(e, n) {
            n.opened && t.push(o(e))
        }), e.when.apply(e, t)
    }

    function f(n, t) {
        setTimeout(function() {
            e(window).trigger(n, t)
        }, 300)
    }
}(jQuery);